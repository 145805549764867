<template>

    <div>
        <div class="vx-row" v-if="item">
    <div class="vx-col w-full lg:w-1/1">
      <!-- ABOUT CARD -->
      <vx-card :equip_name="`Edit ${$i18n.t('equipment')}`" class="mt-base">
        <!-- ACTION SLOT -->
        <template slot="actions">
            <div class="flex gap-2">
                <vs-button color="danger" icon-pack="feather" icon="icon-arrow-left" type="border" @click="$router.push({ name: 'equipment-show' } )">
                    Kembali
                </vs-button>
                <vs-button color="success"  icon="save" @click="storeItem">
                    Save
                </vs-button>
            </div>
        </template>
        <div class="vx-row my-2 w-full md:w-1/2 px-3">
            <label for="" class="font-bold mb-1">Equipment Name</label>
            <vs-input class="w-full" v-model="form.equip_name" placeholder="Please write equip_name" />
        </div>
        <div class="vx-row my-2 w-full md:w-1/2 px-3">
        <label class="font-bold mb-1">{{ $i18n.t('Brand') }}</label>
        <div  class="w-full">
            <v-select v-model="form.brand"  :clearable="true" :options="brands" placeholder="Choose one brand"></v-select>
        </div>
        <div class="vx-row my-2 w-full px-3">
            <label for="" class="font-bold mb-1">Customer</label>
            <v-select v-model="form.customer" class="w-full"  :clearable="true" :options="customers" placeholder="Choose customer"></v-select>
        </div>
        </div>
        <div class="vx-row mt-5">
            <div class="vx-col w-full md:w-1/2">
            <h6 class="font-bold mb-1">equipment In</h6>
            <div class="vx-row mt-5">
                <div class="vx-col w-full md:w-1/2">
                    <div class="vx-row my-2 w-full px-3">
                        <label for="" class="font-bold mb-1">Serial Number</label>
                        <vs-input class="w-full" v-model="form.equip_entry.serial_number" placeholder="Please write equip_name" />
                    </div>
                </div>
                <div class="vx-col w-full md:w-1/2">
                    <div class="vx-row my-2 w-full px-3">
                        <label for="" class="font-bold mb-1">Date</label>
                        <vs-input class="w-full" type="datetime-local" @change="formatDate" v-model="form.equip_entry.date" />
                    </div>
                </div>
            </div>
              <div class="vx-row mt-5">
                  <div class="vx-col w-full md:w-1/2">
                    <img :src="item.equip_entry.picture" class="picture w-full">
                    <vs-button color="primary" size="small" type="border" class="w-full my-4" @click="uploadImageIn">Upload Image</vs-button>
                </div>
                <div class="vx-col w-full md:w-1/2">
                    <div class="vx-row my-2 w-full px-3">
                        <label for="" class="font-bold mb-1">Kelengkapan</label>
                        <vs-textarea class="w-full" rows="2" v-model="form.equip_entry.completeness" />
                    </div>
                    <div class="vx-row my-2 w-full px-3">
                        <label for="" class="font-bold mb-1">Note</label>
                        <vs-textarea class="w-full" rows="2" v-model="form.equip_entry.notes" />
                    </div>
                </div>
            </div>
          </div>
          <div class="vx-col w-full md:w-1/2" v-if="item.equip_exit">
            <h6 class="font-bold mb-1">equipment Out</h6>
            <div class="vx-row mt-5">
                <div class="vx-col w-full md:w-1/2">
                    <div class="vx-row my-2 w-full px-3">
                        <label for="" class="font-bold mb-1">Serial Number</label>
                        <vs-input class="w-full" v-model="form.equip_exit.serial_number" placeholder="Please write equip_name" />
                    </div>
                </div>
                <div class="vx-col w-full md:w-1/2">
                    <div class="vx-row my-2 w-full px-3">
                        <label for="" class="font-bold mb-1">Date</label>
                        <vs-input class="w-full" type="datetime-local" @change="formatDate" v-model="form.equip_exit.date" />
                    </div>
                </div>
            </div>
              <div class="vx-row mt-5">
                  <div class="vx-col w-full md:w-1/2">
                    <img :src="item.equip_exit.picture" class="picture w-full">
                    <vs-button color="primary" size="small" type="border" class="w-full my-4" @click="uploadImageOut">Upload Image</vs-button>
                </div>
                <div class="vx-col w-full md:w-1/2">
                    
                    <div class="vx-row my-2 w-full px-3">
                        <label for="" class="font-bold mb-1">Kelengkapan</label>
                        <vs-textarea class="w-full" rows="2" v-model="form.equip_exit.completeness" />
                    </div>
                    <div class="vx-row my-2 w-full px-3">
                        <label for="" class="font-bold mb-1">Note</label>
                        <vs-textarea class="w-full" rows="2" v-model="form.equip_exit.notes" />
                    </div>
                </div>
            </div>
          </div>
    </div>
    <input id="forequipmentIn" type="file" accept="image/jpeg, image/png, image/jpg" v-on:change="handleFileChangeEquipmentIn" hidden />
    <input id="forequipmentOut" type="file" accept="image/jpeg, image/png, image/jpg" v-on:change="handleFileChangeEquipmentOut" hidden />


      </vx-card>

    </div>
  </div>
    </div>
  
  </template>
  
  <script>
import storage from '@/storage.js'
import moment from 'moment'
import axios from '@/axios';

  
  
  export default {
    name: 'EditequipmentVue',
    computed: {
        encrypt () {
        return this.$secure.encrypt(this.id);
        },

        isDetail(){
        if(this.$route.params.id){
            return true;
        }
        return false;
        }
    },
    data() {
        return {
            id: '',
            uploadMode: '',
            customers: [],
            brands: [],
            form: {
                equip_name: '',
                attribute: [],
                category: null,
                brand: null,
                equip_entry: null,
                equip_exit: null
            },
            categories: [],
            item: []
        }
    },
    methods: {
        handleFileChangeEquipmentIn(event) {
            // Assuming only one file is uploaded
            if (event.target.files.length > 0) {
                this.form.picture = event.target.files[0];
                this.uploadStore()
            }
        },
        handleFileChangeEquipmentOut(event) {
            // Assuming only one file is uploaded
            if (event.target.files.length > 0) {
                this.form.picture = event.target.files[0];
                this.uploadStore()
            }
        },
      formatDate(){
        return moment(this.form.date).format('YYYY-MM-DD HH:mm');
      },
      storeItem(){
        let body = {
            equip_name: this.form.equip_name,
            brand_id: this.form.brand.code.toString(),
            
            serial_number_in: this.form.equip_entry.serial_number,
            date_in: this.form.equip_entry.date,
            completeness_in: this.form.equip_entry.completeness,
            customer: this.form.customer.label,
            note_in: this.form.equip_entry.notes,
            pic_name_in: this.$store.state.AppActiveUser.employee.name
        }
        
        let status = 'in'
        
        if (this.item.equip_exit != null) {
            status = 'out'
            body.serial_number_out = this.form.equip_exit != null ? this.form.equip_exit.serial_number : null
            body.date_out = this.form.equip_exit != null ? this.form.equip_exit.date : null
            body.completeness_out = this.form.equip_exit.completeness
            body.note_out = this.form.equip_exit != null ? this.form.equip_exit.notes : null
            body.pic_name_out = this.$store.state.AppActiveUser.employee.name
        }

        const payload = {
            id: this.id,
            body: body,
            status: status
        }

        this.$store.dispatch("equipment/updateEquipment", payload)
        .then(
            resp => {
                this.$router.push({name: 'equipment-show', params: { id: this.$secure.encrypt(this. id) }});
                this.$vs.notify({
                    title:'Success',
                    text: resp.data.message,
                    color:'success'
                })
            }
        ).catch(
            err => {
                this.$vs.notify({
                    title:'Opps something error',
                    text: err.data.message,
                    color:'danger'
                })
            }
        )

        
        },
        uploadImageIn(){
            this.uploadMode = 'in'
            document.getElementById('forequipmentIn').click();
        },
        uploadImageOut(){
            this.uploadMode = 'out'
            document.getElementById('forequipmentOut').click();
        },
        uploadStore() {

        let payload = {
            status: this.uploadMode,
            id: this.item.id
        }

        const formData = new FormData()
        formData.set("pic_name", this.$store.state.AppActiveUser.employee.name)
        formData.set("picture", this.form.picture)
        payload.body = formData

        this.$store.dispatch("equipment/uploadImage", payload)
        .then(
            resp => {
                this.$store.dispatch("equipment/showEquipment", this.id)
                    .then(
                        resp => {
                        this.item.equip_entry.picture = storage.equipment + resp.data.values.equip_entry.picture
                        if (resp.data.values.equip_exit) {
                            this.item.equip_exit.picture = storage.equipment + resp.data.values.equip_exit.picture
                        }
                        }
                    )
                this.$vs.notify({
                    title:'Success',
                    text: resp.data.message,
                    color:'success'
                })
            }
        ).catch(
            err => {
                this.$vs.notify({
                    title:'Opps something error',
                    text: err.data.message,
                    color:'danger'
                })
            }
        )

        
      },
      getBrands() {
        this.$store.dispatch("equipmentBrand/gets").then(
            resp =>{
                this.brands = resp.data.values.map((brand) => {
                    return {
                        label: brand.brand_name,
                        value: brand.id
                    }
                })
            }
        ).catch(
            err => {
                this.$vs.notify({
                    title:'Opps something error',
                    text: err.data.message,
                    color:'danger'
                })
            }
        )
      },
      getCustomers(){
        axios.get('/customer').then(
          resp => {
            this.customers = resp.data.map(data => {
              return {
                label: data.name,
                code: data.id
              }
            })
          }
        ).catch(
          err => {
            this.$vs.notify({
                title:'Opps something error',
                text: err.data.message,
                color:'danger'
          })
          }
        )
      },
        getData(){
            this.$store.dispatch("equipment/showEquipment", this.id)
            .then((resp) => {
                this.item = resp.data.values
                this.item.equip_entry.date = moment(this.item.equip_entry.date).format("YYYY-MM-DD HH:mm")
                this.item.equip_entry.picture = storage.equipment + this.item.equip_entry.picture
                this.form = {
                    equip_name: this.item.equip_name,
                    brand: {
                        label: this.item.brand.brand_name,
                        code: this.item.brand.id
                    },
                    equip_entry: this.item.equip_entry,
                }
                if (this.item.equip_exit) {
                    this.item.equip_exit.date = moment(this.item.equip_exit.date).format("YYYY-MM-DD HH:mm")
                    this.item.equip_exit.picture = storage.equipment + this.item.equip_exit.picture
                    this.form.equip_exit = this.item.equip_exit
                }
                this.form.customer = this.customers.find(customer => {
                    return customer.label == this.item.customer
                })
                
            }).catch(
                err => {
                    this.$vs.notify({
                        title:'Opps something error',
                        text: err.data.message,
                        color:'danger'
                    })
                }
            )
        }
    },
    created () {
      if(this.$route.params.id){
        this.id = this.$secure.decrypt(this.$route.params.id);
    }
    this.getCustomers();
    this.getBrands();
    this.getData();
    },
  }
  
  </script>
  
  <style lang="scss">
  #page-user-list {
    .user-list-filters {
      .vs__actions {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-58%);
      }
    }
  }
  .picture {
  width: 200px;
  height: 250px;
}
  </style>
  